import { mapGetters } from 'vuex';

export default {
  data: () => ({
    isInvalid: false,
    message: '',
  }),

  computed: mapGetters({
    isLoading: 'Users/getLoading',
    user: 'Users/getUser',
  }),

  beforeDestroy() {
    this.$store.commit('Users/CLEAR_USER');
    this.isInvalid = false;
    this.message = '';
  },

};
