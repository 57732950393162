<template>
  <validation-observer
    ref="usersForm"
    v-slot="{ invalid, handleSubmit }"
  >
    <form
      autocomplete="nope"
      @submit.prevent="handleSubmit($emit('save-user'))"
    >
      <b-field>
        <div class="columns is-vcentered">
          <div
            class="column is-narrow"
            v-if="userPhoto"
          >
            <b-field>
              <figure class="image is-128x128">
                <img
                  :src="userPhoto"
                  :alt="username"
                >
              </figure>
            </b-field>
          </div>

          <div class="column is-narrow">
            <b-field class="file is-info">
              <b-upload
                v-model="userPhoto"
                class="file-label"
              >
                <span class="file-cta">
                  <b-icon
                    class="file-icon"
                    icon="upload"
                  />
                  <span class="file-label">upload photo</span>
                </span>
              </b-upload>
            </b-field>
          </div>
        </div>
      </b-field>

      <validation-provider
        rules="required"
        name="username"
        v-slot="{ errors }"
      >
        <b-field
          horizontal
          label="Username"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input
            size="is-medium"
            v-model="username"
            autocomplete="nope"
          />
        </b-field>
      </validation-provider>

      <validation-provider
        v-show="$route.name === 'AddUser'"
        :rules=" $route.name === 'AddUser' ? 'required|min:6' : ''"
        name="password"
        v-slot="{ errors }"
      >
        <b-field
          horizontal
          label="Password"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input
            password-reveal
            type="password"
            size="is-medium"
            v-model="password"
            autocomplete="nope"
          />
        </b-field>
      </validation-provider>

      <validation-provider
        rules="required"
        name="first name"
        v-slot="{ errors }"
      >
        <b-field
          horizontal
          label="First Name"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input
            size="is-medium"
            v-model="firstName"
          />
        </b-field>
      </validation-provider>

      <validation-provider
        rules=""
        name="middle name"
        v-slot="{ errors }"
      >
        <b-field
          horizontal
          label="Middle Name"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            size="is-medium"
            v-model="middleName"
          />
        </b-field>
      </validation-provider>

      <validation-provider
        rules="required"
        name="last name"
        v-slot="{ errors }"
      >
        <b-field
          horizontal
          label="Last Name"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input
            size="is-medium"
            v-model="lastName"
          />
        </b-field>
      </validation-provider>

      <validation-provider
        rules="required|email"
        name="email"
        v-slot="{ errors }"
      >
        <b-field
          horizontal
          label="Email Address"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <b-input
            type="email"
            size="is-medium"
            v-model="email"
            autocomplete="nope"
          />
        </b-field>
      </validation-provider>

      <validation-provider
        rules="required"
        name="phone"
        v-slot="{ errors }"
      >
        <b-field
          horizontal
          label="Phone"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
          class="is-required"
        >
          <vue-tel-input v-model="phoneCell" />
        </b-field>
      </validation-provider>

      <validation-provider
        rules=""
        name="external id"
        v-slot="{ errors }"
      >
        <b-field
          horizontal
          label="External ID"
          :type="{ 'is-danger': errors[0] }"
          :message="errors"
        >
          <b-input
            size="is-medium"
            v-model="externalId"
          />
        </b-field>
      </validation-provider>

      <b-field
        horizontal
        label="Reset Password"
      >
        <div class="columns">
          <div class="column is-narrow">
            <b-radio-button
              type="in-info"
              v-model="resetPassword"
            >
              <b-icon icon="redo" />
              <span>reset password</span>
            </b-radio-button>
          </div>
        </div>
      </b-field>

      <b-field>
        <b-button
          :disabled="invalid"
          tag="input"
          native-type="submit"
          type="is-success"
          icon-left="save"
          value="save user"
        >
          save user
        </b-button>
      </b-field>
    </form>
  </validation-observer>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {

  name: 'RolesForm',

  components: {
    ValidationObserver,
    ValidationProvider,
  },

  computed: {
    userPhoto: {
      get() {
        return this.$store.state.Users.user.userPhoto;
      },

      set(value) {
        return this.getBase64(value, (imageUrl) => {
          this.$store.commit('Users/SET_USER_USER_PHOTO', imageUrl);
        });
      },
    },

    username: {
      get() {
        return this.$store.state.Users.user.username;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_USERNAME', value);
      },
    },

    password: {
      get() {
        return this.$store.state.Users.user.password;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_PASSWORD', value);
      },
    },

    firstName: {
      get() {
        return this.$store.state.Users.user.firstName;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_FIRST_NAME', value);
      },
    },

    middleName: {
      get() {
        return this.$store.state.Users.user.middleName;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_MIDDLE_NAME', value);
      },
    },

    lastName: {
      get() {
        return this.$store.state.Users.user.lastName;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_LAST_NAME', value);
      },
    },

    email: {
      get() {
        return this.$store.state.Users.user.email;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_EMAIL', value);
      },
    },

    phoneCell: {
      get() {
        return this.$store.state.Users.user.phoneCell;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_PHONE_CELL', value);
      },
    },

    externalId: {
      get() {
        return this.$store.state.Users.user.externalId;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_EXTERNAL_ID', value);
      },
    },

    resetPassword: {
      get() {
        return this.$store.state.Users.user.resetPassword;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_RESET_PASSWORD', value);
      },
    },
  },

  methods: {
    getBase64(img, callback) {
      const reader = new FileReader();
      reader.addEventListener('load', () => callback(reader.result));
      reader.readAsDataURL(img);
    },

    openAttachment() {
      document.getElementById('attachment').click();
    },
  },
};
</script>

<style lang="css" scoped>
</style>
