<template>
  <div>
    <table-header
      @export-pdf="exportPdf"
      @export-xlsx="exportXlsx"
    />

    <b-table
      :data="users"
      :loading="loading"
      checkable
      backend-pagination
      :checked-rows.sync="checkedRows"
      paginated
      :total="total"
      :per-page="perPage"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      narrowed
      striped
      icon-pack="fas"
    >
      <b-table-column
        v-slot="props"
        custom-key="avatar"
      >
        <figure class="image is-32x32">
          <img
            style="width: 32px; height: 32px"
            class="is-rounded"
            :src="props.row.userPhoto ? props.row.userPhoto : defaultImage"
            :alt="props.row.username"
          >
        </figure>
      </b-table-column>

      <b-table-column
        sortable
        searchable
        label="Name"
        field="fullName"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <b-input
            size="is-small"
            v-model="props.filters['fullName']"
            placeholder="Search..."
            icon="search"
          />
        </template>

        <template v-slot="props">
          <p class="has-text-weight-bold">
            {{ props.row.fullName | capitalize }}
          </p>
          <a
            :href="`mailto: ${props.row.email}`"
            class="has-text-info has-text-weight-semibold"
          >
            {{ props.row.email }}
          </a>
        </template>
      </b-table-column>

      <b-table-column
        sortable
        searchable
        label="Organization"
        field="organization"
      >
        <template
          slot="searchable"
          slot-scope="props"
        >
          <b-input
            size="is-small"
            v-model="props.filters['organization']"
            placeholder="Search..."
            icon="search"
          />
        </template>

        <template v-slot="props">
          <router-link
            v-if="props.row.organization"
            :to="{ name: 'EditOrganization', params: { organizationId: props.row.organization.id }}"
            class="tag has-text-weight-bold has-text-grey is-capitalized"
          >
            {{ props.row.organization.name }}
          </router-link>
        </template>
      </b-table-column>

      <b-table-column
        v-slot="props"
        label="Role"
        field="role"
      >
        <b-dropdown aria-role="list">
          <button
            class="button is-capitalized has-text-grey"
            slot="trigger"
            slot-scope="{ active }"
          >
            <span>
              {{
                props.row.role ?
                  props.row.role.replace(/_/g, ' ').substring(5).toLowerCase() : ''
              }}
            </span>
            <b-icon
              :icon="active ? 'chevron-up' : 'chevron-down'"
              size="is-small"
            />
          </button>

          <b-dropdown-item
            v-for="role in roles"
            :key="role.id"
            aria-role="listitem"
            @click="$emit('assign-role', { userId: [props.row.id], roleId: role.id })"
          >
            {{ role.name | role }}
          </b-dropdown-item>
        </b-dropdown>
      </b-table-column>

      <b-table-column
        v-slot="props"
        custom-key="actions"
      >
        <div class="buttons">
          <b-tooltip
            type="is-info"
            label="Edit"
            position="is-bottom"
          >
            <b-button
              tag="router-link"
              :to="{ name: 'EditUser', params: {userId: props.row.id}}"
              type="is-text"
              class="has-text-grey"
              icon-right="edit"
            />
          </b-tooltip>

          <b-tooltip
            type="is-info"
            label="Email"
            position="is-bottom"
          >
            <b-button
              type="is-text"
              class="has-text-grey"
              icon-right="envelope"
              @click="$emit('send-email', props.row.email)"
            />
          </b-tooltip>
        </div>
      </b-table-column>

      <template slot="empty">
        <empty-table table-name="users" />
      </template>

      <template slot="footer">
        <div class="columns is-mobile">
          <div class="column has-background-primary">
            <span class="has-text-white is-uppercase">
              Records {{ perPage }} of {{ total }} records
            </span>
          </div>

          <div class="column is-narrow has-background-info">
            <b-dropdown
              v-model="perPage"
              aria-role="list"
              position="is-bottom-left"
            >
              <b-button
                size="is-small"
                type="is-info"
                slot="trigger"
                icon-right="chevron-down"
              >
                {{ perPage }}
              </b-button>

              <template v-for="(i, index) in pages">
                <b-dropdown-item
                  :key="index"
                  :value="i"
                >
                  {{ i }}
                </b-dropdown-item>
                <hr
                  :key="i"
                  class="dropdown-divider"
                >
              </template>
            </b-dropdown>
          </div>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import Papa from 'papaparse';
import fileDownload from 'js-file-download';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';

import { EmptyTable, TableHeader } from '@/components/Shared';

import defaultImage from '@/assets/images/avatar.svg';

export default {

  name: 'UsersTable',

  components: {
    EmptyTable,
    TableHeader,
  },

  props: {
    roles: {
      type: Array,
      required: true,
    },
  },

  data: () => ({
    columns: [
      { title: 'First Name', dataKey: 'firstName' },
      { title: 'Middle Name', dataKey: 'middleName' },
      { title: 'Last Name', dataKey: 'lastName' },
      { title: 'Email', dataKey: 'email' },
      { title: 'Phone No.', dataKey: 'phoneCell' },
    ],
    defaultImage,
    total: 0,
    page: 1,
    perPage: 10,
    pages: [10, 25, 50, 100],
    checkedRows: [],
  }),

  computed: {
    ...mapGetters({
      users: 'Users/getUsers',
      loading: 'Users/getLoading',
    }),
  },

  filters: {
    role(value) {
      return value.replace(/_/g, ' ').substring(5).toLowerCase();
    },
  },

  methods: {
    async fetchUsers() {
      try {
        const response = await this.$store.dispatch('Users/fetchUsers', { page: this.page, perPage: this.perPage });
        let currentTotal = response.totalElements;
        if (response.totalElements / this.perPage > response.totalElements) {
          currentTotal = this.perPage * response.totalElements;
        }
        this.total = currentTotal;
        return this.$buefy.notification.open({
          message: 'Successfully retrieved the users',
          type: 'is-success',
          position: 'is-bottom-right',
        });
      } catch (error) {
        return this.$buefy.notification.open({
          message: 'Error retrieving the users',
          type: 'is-warning',
          position: 'is-bottom-right',
        });
      }
    },

    onPageChange(page) {
      this.page = page;
      this.fetchUsers();
    },

    customizeTable() {},

    exportPdf() {
      // eslint-disable-next-line new-cap
      const doc = new jsPDF();
      doc.autoTable({
        columns: this.columns,
        body: this.checkedRows.length >= 1 ? this.checkedRows : this.users,
        didParseCell: (data) => {
          if (data.column.raw.displayProperty) {
            const prop = data.column.raw.displayProperty;
            const text = data.cell.raw[prop];
            if (text && text.length > 0) {
              // eslint-disable-next-line no-param-reassign
              data.cell.text = text;
            }
          }
        },
      });
      doc.save('users.pdf');
    },

    exportXlsx() {
      const blob = new Blob(
        [Papa.unparse(this.checkedRows.length >= 1 ? this.checkedRows : this.users)],
        { type: 'text/csv;charset=utf-8;' },
      );
      fileDownload(blob, 'users.csv');
    },
  },

  mounted() {
    this.fetchUsers();
  },
};
</script>

<style lang="css" scoped>

</style>
