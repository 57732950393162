<template>
  <div>
    <!-- page header component -->
    <page-header name="edit user">
      <template #icon>
        <b-icon
          icon="users"
          icon-pack="fas"
        />
      </template>

      <template #actions>
        <b-field>
          <b-switch
            v-model="isActive"
            type="is-info"
          >
            Active
          </b-switch>
        </b-field>
      </template>
    </page-header>

    <section class="section">
      <div class="container">
        <div class="columns">
          <div class="column is-three-fifths">
            <!-- loading component -->
            <b-loading
              :is-full-page="false"
              v-model="isLoading"
              :can-cancel="false"
            />

            <!-- error message component -->
            <b-message
              v-model="isInvalid"
              type="is-warning"
              has-icon
            >
              <strong>{{ message }}</strong>
            </b-message>

            <!-- users form component -->
            <users-form @save-user="updateUser" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { PageHeader } from '@/components/Shared';
import { UsersForm } from '@/components/Users';

import UsersMixin from '@/mixins/UsersMixin';

export default {

  name: 'EditUser',

  components: {
    PageHeader,
    UsersForm,
  },

  mixins: [UsersMixin],

  computed: {
    isActive: {
      get() {
        return this.$store.state.Users.user.isActive;
      },

      set(value) {
        return this.$store.commit('Users/SET_USER_IS_ACTIVE', value);
      },
    },
  },

  created() {
    this.$store.dispatch('Users/fetchUser', this.$route.params.userId);
  },

  methods: {
    async updateUser() {
      try {
        this.isInvalid = false;
        const data = { userId: this.$route.params.userId, ...this.user };
        delete data.role;
        data.userPhoto = data.userPhoto.startsWith('http')
          ? null : data.userPhoto;
        delete data.password;
        await this.$store.dispatch('Users/updateUser', data);
        this.$router.push('/users');
      } catch (error) {
        this.isInvalid = true;
        this.message = error.message;
      }
    },
  },
};
</script>

<style lang="css" scoped>
</style>
