var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"usersForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('form',{attrs:{"autocomplete":"nope"},on:{"submit":function($event){$event.preventDefault();handleSubmit(_vm.$emit('save-user'))}}},[_c('b-field',[_c('div',{staticClass:"columns is-vcentered"},[(_vm.userPhoto)?_c('div',{staticClass:"column is-narrow"},[_c('b-field',[_c('figure',{staticClass:"image is-128x128"},[_c('img',{attrs:{"src":_vm.userPhoto,"alt":_vm.username}})])])],1):_vm._e(),_c('div',{staticClass:"column is-narrow"},[_c('b-field',{staticClass:"file is-info"},[_c('b-upload',{staticClass:"file-label",model:{value:(_vm.userPhoto),callback:function ($$v) {_vm.userPhoto=$$v},expression:"userPhoto"}},[_c('span',{staticClass:"file-cta"},[_c('b-icon',{staticClass:"file-icon",attrs:{"icon":"upload"}}),_c('span',{staticClass:"file-label"},[_vm._v("upload photo")])],1)])],1)],1)])]),_c('validation-provider',{attrs:{"rules":"required","name":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"horizontal":"","label":"Username","type":{ 'is-danger': errors[0] },"message":errors}},[_c('b-input',{attrs:{"size":"is-medium","autocomplete":"nope"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})],1)]}}],null,true)}),_c('validation-provider',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.name === 'AddUser'),expression:"$route.name === 'AddUser'"}],attrs:{"rules":_vm.$route.name === 'AddUser' ? 'required|min:6' : '',"name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"horizontal":"","label":"Password","type":{ 'is-danger': errors[0] },"message":errors}},[_c('b-input',{attrs:{"password-reveal":"","type":"password","size":"is-medium","autocomplete":"nope"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"first name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"horizontal":"","label":"First Name","type":{ 'is-danger': errors[0] },"message":errors}},[_c('b-input',{attrs:{"size":"is-medium"},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=$$v},expression:"firstName"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":"middle name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"Middle Name","type":{ 'is-danger': errors[0] },"message":errors}},[_c('b-input',{attrs:{"size":"is-medium"},model:{value:(_vm.middleName),callback:function ($$v) {_vm.middleName=$$v},expression:"middleName"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"last name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"horizontal":"","label":"Last Name","type":{ 'is-danger': errors[0] },"message":errors}},[_c('b-input',{attrs:{"size":"is-medium"},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required|email","name":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"horizontal":"","label":"Email Address","type":{ 'is-danger': errors[0] },"message":errors}},[_c('b-input',{attrs:{"type":"email","size":"is-medium","autocomplete":"nope"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"required","name":"phone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"is-required",attrs:{"horizontal":"","label":"Phone","type":{ 'is-danger': errors[0] },"message":errors}},[_c('vue-tel-input',{model:{value:(_vm.phoneCell),callback:function ($$v) {_vm.phoneCell=$$v},expression:"phoneCell"}})],1)]}}],null,true)}),_c('validation-provider',{attrs:{"rules":"","name":"external id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"horizontal":"","label":"External ID","type":{ 'is-danger': errors[0] },"message":errors}},[_c('b-input',{attrs:{"size":"is-medium"},model:{value:(_vm.externalId),callback:function ($$v) {_vm.externalId=$$v},expression:"externalId"}})],1)]}}],null,true)}),_c('b-field',{attrs:{"horizontal":"","label":"Reset Password"}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-narrow"},[_c('b-radio-button',{attrs:{"type":"in-info"},model:{value:(_vm.resetPassword),callback:function ($$v) {_vm.resetPassword=$$v},expression:"resetPassword"}},[_c('b-icon',{attrs:{"icon":"redo"}}),_c('span',[_vm._v("reset password")])],1)],1)])]),_c('b-field',[_c('b-button',{attrs:{"disabled":invalid,"tag":"input","native-type":"submit","type":"is-success","icon-left":"save","value":"save user"}},[_vm._v(" save user ")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }